import * as types from './actionTypes';
import * as ajaxActions from './ajaxStatusActions';
import * as jwtActions from './jwtActions';
import {isValidUrl, SERVER_URL} from "../config"

export function reportSearchBegun() {
    return {type: types.REPORT_SEARCH_BEGUN}
}

export function loadReportSuccess(report) {
    return {type: types.REPORT_SEARCH_SUCCESS, report};
}

export function loadReportError(error) {
    return {type: types.REPORT_SEARCH_ERROR, error};
}

export function loadReport(vin, navigate) {
    return (dispatch) => {
        dispatch(reportSearchBegun());
        dispatch(ajaxActions.beginAjaxCall());

        const options = {
            headers: new Headers({"Authorization": "Bearer " + localStorage.getItem('access_token')})
        }
        if (isValidUrl()) {
            fetch(SERVER_URL + 'api/report?vin=' + vin, options)
                .then(response => {
                    if (response.ok || response.status === 422) {
                        return response.json().then(json => ({is200: response.ok, status: response.status, json}))
                    } else {
                        return {is200: false, status: response.status};
                    }
                })
                .then(({is200, json, status}) => {
                    // TODO: should we ever make more requests to servers than just this one, we should make a generalized way of intercepting 401s and logging out and saving the last request
                    if (status === 401) {
                        window.theyMadeARequestForThisVinThatRequiredALogin = vin
                        return jwtActions.logout(
                            {loginSessionExpired: true, companyName: localStorage.getItem('companyName')},
                            navigate,
                        );
                    } else { // since the old access_token hasn't expired, get a new one so that it lasts another 30 minutes
                        dispatch(jwtActions.refreshToken());
                    }

                    if (is200) {
                        return loadReportSuccess(json)
                    } else {
                        return loadReportError(json)
                    }
                })
                .then(action => dispatch(action))
                .then(() => dispatch(ajaxActions.endAjaxCall()))
                .catch(error => {
                    dispatch(ajaxActions.ajaxCallErroredOut());
                    throw (error);
                });
        }
    };
}
